import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { version } from '../version.json';
import { ChargementService } from './partage/components/chargement/chargement.service';
import { ChargementComponent } from './partage/components/chargement/chargement.component';

import { LoginComponent } from './partage/components/login/login.component';
import { ConnexionService } from './partage/components/login/connexion.service';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import { AlertComponent } from './partage/components/alert/alert.component';
import { FeatureFlagService } from './partage/feature-flag/feature-flag.service';
import { FeatureFlags } from './feature-flags';
import { map } from 'rxjs';
import { FeatureFlagDirective } from './partage/feature-flag/feature-flag.directive';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    imports: [
        CommonModule,
        RouterOutlet,
        RouterLink,
        RouterLinkActive,
        MatSidenavModule,
        MatButtonModule,
        MatListModule,
        MatIconModule,
        CdkAccordionModule,
        ChargementComponent,
        LoginComponent,
        AlertComponent,
        NgOptimizedImage,
        FeatureFlagDirective,
    ]
})
export class AppComponent extends ConnexionService {
  [x: string]: any;
  expandedIndex = 0;
  items: itemMenu[] = [];
  version: string;

  isLoading() {
    return this.chargementservice.isLoading();
  }

  constructor(
    private chargementservice: ChargementService,
    private auth: MsalService,
    private msal: MsalBroadcastService,
    private featureFlagService: FeatureFlagService,
    @Inject(MSAL_GUARD_CONFIG) private msalConfig: MsalGuardConfiguration
  ) {
    super(msalConfig, msal, auth);
    this.version = version;

    this.featureFlagService
      .getFeaturesFlags()
      .pipe(
        map((featureFlags) => {
         
          this.items = [
            {
              id: 1,
              Libelle: 'Redditions',
              avecAccordion: false,
              sousMenu: null,
              routerLink: '/redditions/evenements',
              fontIcon: 'receipt_long',
            },
            {
              id: 2,
              Libelle: 'Rapprochements',
              avecAccordion: true,
              fontIcon: 'merge',
              sousMenu: [
                {
                  Libelle: 'Rapprochement manuel',
                  routerLink: '/rapprochement/rapprochement-manuel',
                  fontIcon: 'rebase_edit',
                  enabled: true,
                },
                {
                  Libelle: 'Rapprochements',
                  routerLink: '/rapprochement/rapprochements',
                  fontIcon: 'playlist_add_check',
                  enabled:
                    featureFlags[
                      FeatureFlags.RAPPROCHEMENTBANCAIRE_LISTE_RAPPROCHEMENTS
                    ],
                },
              ],
              routerLink: null,
            },
          ];
        })
      )
      .subscribe();
  }
  featureflagsVersion = FeatureFlags.VERSION;
}

export interface itemMenu {
  id: number;
  Libelle: string;
  routerLink: string | null;
  avecAccordion: boolean;
  fontIcon: string;
  sousMenu: itemSousMenu[] | null;
}

export interface itemSousMenu {
  Libelle: string;
  routerLink: string;
  fontIcon: string;
  enabled: boolean;
}
