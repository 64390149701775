import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { EvenementsRouteComponent } from './routes/redditions/evenements/evenements.route.component';
import { RapprochementManuelRouteComponent } from './routes/rapprochement/rapprochement-manuel/rapprochement-manuel.route.component';
import { PageNotFoundComponent } from './partage/components/page-not-found/page-not-found.component';
import { LoginComponent } from './partage/components/login/login.component';
import { PanelEvenementDetailComponent } from './routes/redditions/evenements/panel-evenement-detail/panel-evenement-detail/panel-evenement-detail.component';
import { PDFViewerComponent } from './partage/components/PDF-viewer/pdf-viewer/pdf-viewer.component';
import { VersionComponent } from './routes/version/version/version.component';

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
      },
      {
        path: 'redditions/evenements',
        component: EvenementsRouteComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'rapprochement/rapprochement-manuel',
        component: RapprochementManuelRouteComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'version',
        component: VersionComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'rapprochement/rapport/telecharger',
        redirectTo: 'rapprochement/rapprochement-manuel',
      },

      { path: '**', component: PageNotFoundComponent },
    ],
  },
];
