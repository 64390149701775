<div class="login_page">
  <div class="login_body">
    <div class="login_container">
      <div class="logo"></div>
      <div class="Info">
        <div class="info_text">Bienvenue sur</div>
        <div class="logoB"></div>
      </div>
      <div class="btn">
        <button
          type="submit"
          mat-flat-button
          class="btn-primaire"
          (click)="login()"
        >
          Se connecter
        </button>
      </div>
    </div>
  </div>
  <div class="login_footer">
    <div class="logo_footer"></div>
    <p>Copyright Tick&Live ©{{ annee }} - tous droits réservés</p>
  </div>
</div>
