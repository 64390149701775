import { Component, inject, Inject, model } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
  MatDialogClose,
  MatDialogActions,
  MatDialogRef,
} from '@angular/material/dialog';
import { dialogSubmitData } from './dialog-submit.interfaces';

@Component({
  selector: 'app-dialog-submit',
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatDialogContent,
  ],
  templateUrl: './dialog-submit.component.html',
  styleUrl: './dialog-submit.component.scss',
})
export class DialogSubmitComponent {
  readonly dialogRef = inject(MatDialogRef<DialogSubmitComponent>);
  readonly data = inject<dialogSubmitData>(MAT_DIALOG_DATA);
  readonly valide = model<boolean>(true);
  readonly refuse = model<boolean>(false);
  
}
