<div class="body-container">
  <div class="content-header rm_header">
    <div class="list_btn">
      <button
        type="button"
        mat-flat-button
        class="bsbtn btn-primaire"
        (click)="telechargerFichier('reglements')"
      >
        Télécharger les règlements non rapprochés
      </button>
      <button
        type="button"
        mat-flat-button
        class="bsbtn btn-primaire"
        (click)="telechargerFichier('paiements')"
      >
        Télécharger les paiements non rapprochés
      </button>
    </div>
  </div>
  <div class="rapprochement-body flex flex-1 aic jcc">
    <form
      [formGroup]="rapprochementForm"
      class="rapprochement-form aic jcc flex body-sub-container"
    >
      <div class="flex-1 flex rapprochement-form-container">
        <mat-form-field
          appearance="outline"
          class="rapprochement-column-element client-element"
        >
          <mat-label>Clients</mat-label>
          <input
            #clientInput
            type="text"
            matInput
            formControlName="client"
            [matAutocomplete]="autoClient"
            (input)="filterClient()"
            (focus)="filterClient()"
            (click)="clearClient()"
          />
          @if(rapprochementForm.controls.client.value){
          <button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="$event.stopPropagation(); clearClient()"
          >
            <mat-icon>close</mat-icon>
          </button>
          }@else{
          <button matSuffix mat-icon-button>
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          } @if (rapprochementForm.controls.client.hasError('required')) {
          <mat-error>Le client est obligatoire </mat-error>
          }
          <mat-autocomplete
            #autoClient="matAutocomplete"
            requireSelection
            [displayWith]="displayClient"
          >
            @for (client of filteredClient; track client) {
            <mat-option [value]="client">{{ client.libelleClient }}</mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="flex-1 flex rapprochement-form-container">
        <mat-form-field
          appearance="outline"
          class="rapprochement-column-element"
        >
          <mat-label>ID Règlement (Aparté)</mat-label>
          <input
            #reglementInput
            type="text"
            matInput
            formControlName="reglement"
            [matAutocomplete]="autoRgl"
            (input)="filterReglement()"
            (focus)="filterReglement()"
            (click)="clearReglement()"
          />
          @if(rapprochementForm.controls.reglement.value){
          <button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="$event.stopPropagation(); clearReglement()"
          >
            <mat-icon>close</mat-icon>
          </button>
          }@else{
          <button matSuffix mat-icon-button>
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          } @if (rapprochementForm.controls.reglement.hasError('required')) {
          <mat-error>L'id règlement est obligatoire </mat-error>
          }

          <mat-autocomplete
            #autoRgl="matAutocomplete"
            requireSelection
            [displayWith]="displayReglement"
          >
            @for (reg of filteredReglement(); track $index) {
            <mat-option [value]="reg">{{
              reg.referenceBancaire
                ? reg.referenceBancaire
                : reg.idFinancialAparte
            }}</mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="rapprochement-column-element"
        >
          <mat-label>ID Paiement (Banque)</mat-label>
          <input
            #paiementInput
            type="text"
            matInput
            formControlName="paiement"
            [matAutocomplete]="autoPaie"
            (input)="filterPaiement()"
            (focus)="filterPaiement()"
            (click)="clearPaiement()"
          />
          @if(rapprochementForm.controls.paiement.value){
          <button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="$event.stopPropagation(); clearPaiement()"
          >
            <mat-icon>close</mat-icon>
          </button>
          }@else{
          <button matSuffix mat-icon-button>
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          }

          <mat-autocomplete
            #autoPaie="matAutocomplete"
            requireSelection
            [displayWith]="displayPaiement"
          >
            @for (paie of filteredPaiement(); track $index) {
            <mat-option [value]="paie">{{ paie.referenceBancaire }}</mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="flex flex-1 rapprochement-form-container">
        <mat-form-field
          appearance="outline"
          class="rapprochement-column-element"
        >
          <mat-label>Montant Règlement</mat-label>
          <input
            matInput
            type="number"
            disabled="true"
            value="{{
              this.rapprochementForm.controls.reglement.value?.montantEnEuros
            }}"
          />
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          class="rapprochement-column-element"
        >
          <mat-label>Montant Paiement</mat-label>
          <input
            matInput
            type="number"
            disabled="true"
            value="{{
              this.rapprochementForm.controls.paiement.value?.montantEnEuros
            }}"
          />
        </mat-form-field>
      </div>

      <div class="flex">
        @if( rapprochementForm.hasError('ControleMontantRglPai') &&
        !rapprochementForm.hasError('ControleGlobale') ) {
        <mat-error class="globaleErreur aic jcc">
          <mat-card appearance="outlined"
            ><mat-card-content
              >Les montants du règlement et du paiement ne correspondent
              pas</mat-card-content
            ></mat-card
          ></mat-error
        >
        } @if (rapprochementForm.hasError('ControleGlobale') &&
        !rapprochementForm.controls.reglement.hasError('required')) {
        <mat-error class="globaleErreur aic jcc">
          <mat-card appearance="outlined"
            ><mat-card-content
              >Sélectionnez un paiement ou cochez la case non
              rapprochable</mat-card-content
            ></mat-card
          >
        </mat-error>
        }
      </div>

      <div class="flex-1 flex rapprochement-form-container">
        <div class="rapprochement-column-element aic jcc">
          <mat-checkbox class="aic jcc flex" formControlName="nonRappochable"
            >Non Rapprochable</mat-checkbox
          >
        </div>
        <div class="rapprochement-column-element aic jcc">
          <button
            type="submit"
            mat-flat-button
            class="btn-primaire"
            (click)="openDialog()"
            [disabled]="rapprochementForm.invalid"
          >
            Valider
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
