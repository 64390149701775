import { HttpClient } from '@angular/common/http';
import { computed, inject, Injectable, signal } from '@angular/core';
import { filter, map, Observable, switchMap, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import {
  Client,
  Fichier,
  Paiement,
  Reglement,
} from './rapprochement-manuel.interface';
import saveAs from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class RapprochementManuelService {
  private _httpClient = inject(HttpClient);

  private readonly getClients$ = this._httpClient.get<Client[]>(
    `${environment.backendUrl}rapprochement-bancaire/liste-clients`
  );
  clients = toSignal(this.getClients$, {
    initialValue: [] as Client[],
  });
  clientSelection = signal<Client | undefined>(undefined);
  paiementSelection = signal<Paiement | undefined>(undefined);
  reglementSelection = signal<Reglement | undefined>(undefined);
  commentaire = signal<string | undefined>(undefined);
  urlClient = signal<string | undefined>(undefined);
  urlReg = signal<string | undefined>(undefined);
  urlPaie = signal<string | undefined>(undefined);
  reglements = signal<Reglement[]>([]);
  paiements = signal<Paiement[]>([]);
  reglementFilter = signal('');
  paiementFilter = signal('');
  nonRapprochable = signal(false);
  faireRapprochementManuel = signal(false);
  resetForm = signal(false);
  typeFichier = signal<string | undefined>(undefined);
  nomFichier = signal<string | undefined>(undefined);

  estNonRappro = computed(() => {
    const x =
      this.clientSelection() &&
      this.reglementSelection() &&
      !this.paiementSelection();

    if (x) {
      return x;
    }

    return false;
  });

  private clientPreFilter = toSignal(
    toObservable(this.clients).pipe(
      filter(() => this.urlClient() != ''),
      map((cli) => cli?.find((c) => c.codeClient === this.urlClient())),
      filter(Boolean),
      map((client) => {
        this.clientSelection.set(client);
        this.urlClient.set('');
      })
    )
  );

  private clientChange = toSignal(
    toObservable(this.clientSelection).pipe(
      filter(Boolean),
      switchMap((cli) => {
        return this.reglements$(cli.codeClient).pipe(
          map((reg) => {
            this.reglements.set(reg);
            const url = this.urlReg();
            if (url) {
              this.reglementSelection.set(
                reg!.find(
                  (r) => r.idReglement.toUpperCase() === url.toUpperCase()
                )
              );
              this.urlReg.set(undefined);
            }
            return cli;
          })
        );
      }),
      switchMap((cli) => {
        return this.paiement$(cli.codeClient).pipe(
          map((paie) => {
            this.paiements.set(paie);
            const url = this.urlPaie();
            if (url) {
              this.paiementSelection.set(
                paie!.find(
                  (p) => p.idPaiement.toUpperCase() === url.toUpperCase()
                )
              );
              this.urlReg.set(undefined);
            }
          })
        );
      })
    )
  );

  private reglements$(cli: string): Observable<Reglement[]> {
    return this._httpClient.get<Reglement[]>(
      `${environment.backendUrl}rapprochement-bancaire/liste-reglements-non-rapproches-client/${cli}`
    );
  }

  private paiement$(cli: string): Observable<Paiement[]> {
    return this._httpClient.get<Paiement[]>(
      `${environment.backendUrl}rapprochement-bancaire/liste-paiements-non-rapproches-client/${cli}`
    );
  }

  filteredReglement = computed(() =>
    this.reglements()?.filter(
      (reg) =>
        (reg.referenceBancaire.toLowerCase().includes(this.reglementFilter()) &&
          reg.referenceBancaire
            .toLowerCase()
            .includes(
              this.paiementSelection()
                ? this.paiementSelection()!.referenceBancaire
                : ''
            )) ||
        reg.montantEnEuros === this.paiementSelection()?.montantEnEuros
    )
  );

  filteredPaiement = computed(() =>
    this.paiements()?.filter(
      (paie) =>
        paie.referenceBancaire.toLowerCase().includes(this.paiementFilter()) &&
        (paie.referenceBancaire
          .toLowerCase()
          .includes(
            this.reglementSelection()
              ? this.reglementSelection()!.referenceBancaire
              : ''
          ) ||
          paie.montantEnEuros === this.reglementSelection()?.montantEnEuros)
    )
  );

  private nonRappro = computed(() => ({
    nonRappro: this.nonRapprochable(),
    reg: this.reglementSelection(),
    commentaire: this.commentaire(),
  }));

  private mettreNonRapprochable = toSignal(
    toObservable(this.nonRappro).pipe(
      filter((info) => info.nonRappro && info.reg !== undefined),
      switchMap((info) => {
        return this.putNonRapprochable$(
          info.reg!.idReglement,
          info.commentaire!
        ).pipe(
          map(() => {
            this.nonRapprochable.set(false);
            this.resetForm.set(true);
          })
        );
      })
    )
  );

  private rapproManuel = computed(() => ({
    rapproMan: this.faireRapprochementManuel(),
    reg: this.reglementSelection(),
    paie: this.paiementSelection(),
    commentaire: this.commentaire(),
  }));

  private faireRapproManuel = toSignal(
    toObservable(this.rapproManuel).pipe(
      filter((x) => x.paie !== undefined && x.rapproMan && x.reg != undefined),
      switchMap((info) => {
        return this.postRapprochementManuel$(
          info.reg!.idReglement,
          info.paie!.idPaiement,
          info.commentaire!
        ).pipe(
          map(() => {
            this.faireRapprochementManuel.set(false);
            this.resetForm.set(true);
          })
        );
      })
    )
  );

  private putNonRapprochable$(
    idReglement: string,
    commentaire: string
  ): Observable<object> {
    const requestUrl = `${environment.backendUrl}rapprochement-bancaire/reglements/${idReglement}/passer-non-rapprochable`;
    return this._httpClient.put(requestUrl, { commentaire });
  }

  private postRapprochementManuel$(
    idReglement: string,
    idPaiement: string,
    commentaire: string
  ) {
    const requestUrl = `${environment.backendUrl}rapprochement-bancaire/rapprochement-manuel`;
    return this._httpClient.post(requestUrl, {
      idReglement,
      idPaiement,
      commentaire,
    });
  }

  fichier = signal<Fichier | undefined>(undefined);

  private x = toSignal(
    toObservable(this.fichier).pipe(
      filter(Boolean),
      filter((f) => f.nom !== undefined && f.type !== undefined),
      switchMap((x) => {
        return this.telechargerFichierRapprochement$(x.type).pipe(
          map((blob) => {
            saveAs(blob, x.nom);
            this.typeFichier.set(undefined);
            this.nomFichier.set(undefined);
          })
        );
      })
    )
  );

  private telechargerFichierRapprochement$(
    typeFichier: string
  ): Observable<Blob> {
    const url = `${environment.backendUrl}rapprochement-bancaire/rapport/${typeFichier}`;
    return this._httpClient.get(url, { responseType: 'blob' });
  }
}
