import {
  Component,
  effect,
  ElementRef,
  inject,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, ParamMap } from '@angular/router';

import {
  Client,
  Paiement,
  RapprochementManuelForm,
  Reglement,
} from './rapprochement-manuel.interface';
import { RapprochementManuelService } from './rapprochement-manuel.service';
import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { DialogSubmitComponent } from '../../../partage/components/dialog-submit/dialog-submit.component';
import { dialogSubmitData } from '../../../partage/components/dialog-submit/dialog-submit.interfaces';

import moment from 'moment';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-rapprochement-manuel',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatCardModule,
    MatIconModule,
  ],
  providers: [RapprochementManuelService],
  templateUrl: './rapprochement-manuel.route.component.html',
  styleUrl: './rapprochement-manuel.route.component.scss',
})
export class RapprochementManuelRouteComponent {
  private rapprochementManuelService = inject(RapprochementManuelService);
  private route = inject(ActivatedRoute);
  private dialog = inject(MatDialog);
  clients = this.rapprochementManuelService.clients;
  reglements = this.rapprochementManuelService.reglements;
  paiements = this.rapprochementManuelService.paiements;

  rapprochementForm: FormGroup<RapprochementManuelForm>;
  public filteredClient: Client[] = [];
  public filteredPaiement = this.rapprochementManuelService.filteredPaiement;
  public filteredReglement = this.rapprochementManuelService.filteredReglement;
  @ViewChild('clientInput') clientInput!: ElementRef<HTMLInputElement>;
  @ViewChild('reglementInput') reglementInput!: ElementRef<HTMLInputElement>;
  @ViewChild('paiementInput') paiementInput!: ElementRef<HTMLInputElement>;

  constructor() {
    this.rapprochementForm = new FormGroup<RapprochementManuelForm>(
      {
        client: new FormControl(
          { value: null, disabled: false },
          { nonNullable: true, validators: [Validators.required] }
        ),
        reglement: new FormControl(
          {
            value: null,
            disabled: true,
          },
          [Validators.required]
        ),
        paiement: new FormControl({
          value: null,
          disabled: true,
        }),
        nonRappochable: new FormControl<boolean>(
          {
            value: false,
            disabled: true,
          },
          { nonNullable: true }
        ),
      },
      { validators: [this.ControleGlobale(), this.ControleMontantRglPai()] }
    );

    this.rapprochementForm.controls.client.valueChanges.subscribe((client) => {
      this.rapprochementManuelService.paiementSelection.set(undefined);
      this.rapprochementManuelService.reglementSelection.set(undefined);
      if (client) {
        this.rapprochementForm.controls.paiement.enable();
        this.rapprochementForm.controls.reglement.enable();
      }
      this.rapprochementManuelService.clientSelection.set(client ?? undefined);
    });

    this.rapprochementForm.controls.paiement.valueChanges.subscribe(
      (paiement) => {
        this.rapprochementManuelService.paiementSelection.set(
          paiement ?? undefined
        );
      }
    );
    this.rapprochementForm.controls.reglement.valueChanges.subscribe(
      (reglement) => {
        this.rapprochementManuelService.reglementSelection.set(
          reglement === null ? undefined : reglement
        );
      }
    );

    effect(() => {
      this.rapprochementForm.controls.client.patchValue(
        this.rapprochementManuelService.clientSelection() || null,
        { emitEvent: false }
      );

      this.rapprochementForm.controls.paiement.patchValue(
        this.rapprochementManuelService.paiementSelection() || null,
        { emitEvent: false }
      );
      this.rapprochementForm.controls.reglement.patchValue(
        this.rapprochementManuelService.reglementSelection() || null,
        { emitEvent: false }
      );

      this.rapprochementManuelService.estNonRappro()
        ? this.rapprochementForm.controls.nonRappochable.enable()
        : this.rapprochementForm.controls.nonRappochable.disable();
      if (this.rapprochementManuelService.clientSelection()) {
        this.rapprochementForm.controls.reglement.enable();
        this.rapprochementForm.controls.paiement.enable();
      } else {
        this.rapprochementForm.controls.reglement.disable();
        this.rapprochementForm.controls.paiement.disable();
      }
      if (this.rapprochementManuelService.resetForm()) {
        this.resetForm();
      }
    });
  }

  filterClient(): void {
    const filterValue = this.clientInput.nativeElement.value.toLowerCase();
    this.filteredClient = this.clients()
      ? this.clients()!.filter((o) =>
          o.libelleClient.toLowerCase().includes(filterValue)
        )
      : [];
  }

  filterReglement(): void {
    const filterReglement =
      this.reglementInput.nativeElement.value.toLowerCase();
    this.rapprochementManuelService.reglementFilter.set(filterReglement);
  }

  filterPaiement(): void {
    const filterPaiement = this.paiementInput.nativeElement.value.toLowerCase();
    this.rapprochementManuelService.paiementFilter.set(filterPaiement);
  }

  clearReglement() {
    this.reglementInput.nativeElement.value = '';
    this.rapprochementForm.controls.reglement.setValue(null);
    this.filterReglement();
  }

  clearPaiement() {
    this.paiementInput.nativeElement.value = '';
    this.rapprochementForm.controls.paiement.setValue(null);
    this.filterPaiement();
  }
  clearClient() {
    this.resetForm();
    this.clientInput.nativeElement.value = '';
    this.rapprochementForm.controls.client.setValue(null);

    this.filterClient();
  }

  ControleMontantRglPai(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      var test =
        control.value.paiement === null ||
        control.value.reglement?.montant === control.value.paiement?.montant;
      return test ? null : { ControleMontantRglPai: true };
    };
  }

  ControleGlobale(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      var test =
        control.value.paiement === null &&
        control.value.reglement !== null &&
        control.value.nonRappochable;
      var test2 =
        control.value.reglement !== null && control.value.paiement !== null;

      return test || test2 ? null : { ControleGlobale: true };
    };
  }

  displayPaiement(paiement: Paiement): string {
    return paiement && paiement.referenceBancaire
      ? paiement.referenceBancaire
      : '';
  }

  displayReglement(reglement: Reglement): string {
    return reglement
      ? reglement.referenceBancaire
        ? reglement.referenceBancaire
        : reglement.idFinancialAparte
      : '';
  }

  displayClient(client: Client): string {
    return client && client.libelleClient ? client.libelleClient : '';
  }

  openDialog() {
    this.rapprochementForm?.markAllAsTouched();

    if (this.rapprochementForm.valid) {
      var dialogData: dialogSubmitData = this.rapprochementForm.controls
        .nonRappochable.value
        ? {
            btnAnnuler: 'Annuler',
            btnValide: 'Valider la mise en non rapprochable',
            message:
              'Vous êtes sur le point de mettre le règlement en non rapprochable',
          }
        : {
            btnAnnuler: 'Annuler le rapprochement',
            btnValide: 'Valider le rapprochement',
            message: 'Vous êtes sur le point de valider un rappochement manuel',
          };
      this.dialog
        .open(DialogSubmitComponent, { data: dialogData })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            if (this.rapprochementForm.controls.nonRappochable.value) {
              this.rapprochementManuelService.nonRapprochable.set(true);
            } else {
              this.rapprochementManuelService.faireRapprochementManuel.set(
                true
              );
            }
          }
        });
    }
  }

  telechargerFichier(typeFichier: string) {
    this.rapprochementManuelService.fichier.set({
      nom: `${typeFichier}_non_rapproché_${moment().format(
        'DD_MM_YYYY_HHmmss'
      )}.csv`,
      type: typeFichier,
    });
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.rapprochementManuelService.urlClient.set(
        params.get('codeClient') || ''
      );
      const c = params.get('codeClient');
      const r = params.get('idReglement');
      const p = params.get('idPaiement');
      const t = params.get('type');
      if (c) {
        this.rapprochementManuelService.urlClient.set(c);
      }
      if (r) {
        this.rapprochementManuelService.urlReg.set(r);
      }
      if (p) {
        this.rapprochementManuelService.urlPaie.set(p);
      }
      if (t) {
        this.telechargerFichier(t);
      }
    });
  }

  resetForm() {
    this.rapprochementForm.controls.paiement.reset();
    this.rapprochementForm.controls.reglement.reset();
    this.rapprochementForm.controls.client.reset();
    this.rapprochementForm.controls.nonRappochable.setValue(false);
    this.rapprochementForm.controls.paiement.disable();
    this.rapprochementForm.controls.reglement.disable();
    this.rapprochementForm.controls.nonRappochable.disable();
    this.rapprochementManuelService.resetForm.set(false);
  }
}
