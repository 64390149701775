import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { FeatureFlagService } from './feature-flag.service';

@Directive({
  selector: '[featureEnabled]',
  standalone: true,
})
export class FeatureFlagDirective implements OnInit {
  @Input() featureEnabled: string = null!;

  constructor(
    private el: ElementRef,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit(): void {
    this.featureFlagService
      .isEnabled(this.featureEnabled)
      .subscribe((isEnabled) => {
        if (!isEnabled)
          this.el.nativeElement.parentNode.removeChild(this.el.nativeElement);
      });
  }
}
