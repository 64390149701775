import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'rapprochementStatutClass',
  standalone: true,
})
export class RapprochementStatutClassPipe implements PipeTransform {
  transform(
    statutRapprochementOk: boolean,
    datePlusVieuxReglementNonRapproche: Date
  ): string {
    if (statutRapprochementOk) {
      return 'capsOk';
    } else if (
      moment().diff(moment(datePlusVieuxReglementNonRapproche), 'days') > 3
    ) {
      return 'capsKo';
    } else {
      return 'capsWarning';
    }
  }
}
