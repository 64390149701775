import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { StatutEvenement } from '../evenements.interface';

@Pipe({
  name: 'evenementStatut',
  standalone: true,
})
export class EvenementStatutPipe implements PipeTransform {
  transform(statutEvenement: StatutEvenement): string {
    var statut: StatutEvenement =
      StatutEvenement[
        statutEvenement as unknown as keyof typeof StatutEvenement
      ];
    switch (statut) {
      case StatutEvenement.AControler:
        return 'A contrôler';
      case StatutEvenement.ARedditionner:
        return 'A redditionner';
      case StatutEvenement.Acompte:
        return 'Acompte';
      case StatutEvenement.EnCours:
        return 'En cours';
      case StatutEvenement.Reverse:
        return 'Reversé';
      default:
        return 'Erreur';
    }
  }
}
