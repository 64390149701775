import {
  Component,
  Injectable,
  ViewChild,
  ChangeDetectorRef,
  inject,
  effect,
} from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EvenementsService } from './evenements.service';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  Subject,
} from 'rxjs';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  Client,
  Evenement,
  EvenementFilterForm,
  PdfActon,
  StatutEvenement,
} from './evenements.interface';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatMomentDateModule,
  MomentDateModule,
  provideMomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import {
  MatPaginator,
  MatPaginatorIntl,
  MatPaginatorModule,
  PageEvent,
} from '@angular/material/paginator';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  DatePipe,
  DATE_PIPE_DEFAULT_OPTIONS,
  PercentPipe,
} from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialog } from '@angular/material/dialog';
import { EvenementStatutPipe } from './pipe/evenement-statut.pipe';
import { EvenementStatutClassPipe } from './pipe/evenement-statut-class.pipe';
import { RapprochementStatutClassPipe } from './pipe/rapprochement-statut-class.pipe';
import { MatTooltip } from '@angular/material/tooltip';
import { DialogAcompteRedditionValidationComponent } from './dialog-acompte-reddition-validation/dialog-acompte-reddition-validation.component';

import { ActivatedRoute, ParamMap, RouterLink } from '@angular/router';
import { PanelEvenementDetailComponent } from './panel-evenement-detail/panel-evenement-detail/panel-evenement-detail.component';

import { FactureService } from '../../facturation/facture.service';
import { PDFViewerComponent } from '../../../partage/components/PDF-viewer/pdf-viewer/pdf-viewer.component';
import {
  DialogDataInput,
  DialogReturn,
  TypeDialog,
} from './dialog-acompte-reddition-validation/dialog-acompte-reddition.interface';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import 'moment/locale/fr';

//traduction pour pagination
@Injectable()
export class MyCustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();

  // For internationalization, the `$localize` function from
  // the `@angular/localize` package can be used.
  firstPageLabel = `Premère page`;
  itemsPerPageLabel = `Lignes par page:`;
  lastPageLabel = `Dernière page`;

  // You can set labels to an arbitrary string too, or dynamically compute
  // it through other third-party internationalization libraries.
  nextPageLabel = 'Page suivante';
  previousPageLabel = 'Page précédente';

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return `Page 1 sur 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return `Page ${page + 1} sur ${amountPages}`;
  }
}

@Component({
  selector: 'app-evenements.route',
  templateUrl: './evenements.route.component.html',
  styleUrl: './evenements.route.component.scss',
  animations: [
    trigger('mouseEnter', [
      state('oui', style({ maxWidth: '800px', minWidth: '40%' })),
      state('non', style({ width: '0px', minWidth: '0' })),
      transition(
        'oui <=> non',
        animate('400ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
    trigger('showDetail', [
      state(
        'true',
        style({ backdropFilter: 'blur(5px)', opacity: '1', zIndex: '2000' })
      ),
      state(
        'false',
        style({ backdropFilter: 'blur(0px)', opacity: '0', zIndex: '0' })
      ),
      transition('false => true', [animate('0.4s ease-out')]),
    ]),
    trigger('slider', [
      state('open', style({ width: '70%', minWidth: '70%' })),
      state('close', style({ width: '0', minWidth: '0' })),
      transition('open <=> close', [
        animate('400ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ]),
    ]),
  ],
  providers: [
    EvenementsService,
    { provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl },
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: { dateFormat: 'dd/MM/YYYY HH:mm:ss', timezone: '+0000' },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'fr' },
    provideMomentDateAdapter(),
  ],
  imports: [
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatIconModule,
    MomentDateModule,
    MatSlideToggleModule,
    DatePipe,
    PercentPipe,
    EvenementStatutPipe,
    EvenementStatutClassPipe,
    RapprochementStatutClassPipe,
    MatTooltip,
    RouterLink,
    PanelEvenementDetailComponent,
    ReactiveFormsModule,
    PDFViewerComponent,
    FormsModule,
  ],
})
export class EvenementsRouteComponent {
  private evenementService = inject(EvenementsService);
  private dialog = inject(MatDialog);
  private cdr = inject(ChangeDetectorRef);
  private factureService = inject(FactureService);
  private route = inject(ActivatedRoute);

  nomColumns: string[] = [
    'nom',
    'idAparte',
    'date',
    'nomClient',
    'statutRapprochementOk',
    'montantTotal',
    'totalReverse',
    'montantDu',
    'statutEvenement',
  ];
  nomColumnsAvecDetail = [...this.nomColumns, 'slider'];

  evenements = this.evenementService.evenement;
  evenementDetail: Evenement | null = null;
  evenementEnter: Evenement | null = null;
  isChecked = false;
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  selection = new SelectionModel<Evenement>(true, []);
  filtre = new FormControl('');
  voirDetailEvenement = this.evenementService.voirDetail;
  panelEvenement = this.evenementService.evenementSelection;
  filteredClient = this.evenementService.filteredClient;
  clientSelection = this.evenementService.clientSelection;
  factureEnCours = this.evenementService.factureEnCours;

  handlePageEvent(e: PageEvent) {
    this.filterForm.controls.nbrParPage.setValue(e.pageSize);
    this.filterForm.controls.numeroPage.setValue(e.pageIndex);
  }

  filterForm: FormGroup<EvenementFilterForm>;

  pdfBlob = this.factureService.blob;
  filename = this.factureService.filename;
  voirPDF = this.factureService.voirPdf;

  constructor() {
    // Assign the data to the data source for the table to render

    this.filterForm = new FormGroup<EvenementFilterForm>({
      numeroPage: new FormControl<number>(
        { value: 0, disabled: false },
        { nonNullable: true }
      ),
      nbrParPage: new FormControl<number>(
        { value: 25, disabled: false },
        { nonNullable: true }
      ),
      orderingField: new FormControl<string>(
        { value: 'date', disabled: false },
        { nonNullable: true }
      ),
      orderingDirection: new FormControl<number>(
        { value: 0, disabled: false },
        { nonNullable: true }
      ),
      codeClients: new FormControl<string[]>(
        {
          value: [],
          disabled: false,
        },
        { nonNullable: true }
      ),
      rechercheEvenement: new FormControl<string>(
        { value: '', disabled: false },
        { nonNullable: true, validators: [Validators.minLength(3)] }
      ),
      aRedditionnerUniquement: new FormControl<boolean>(
        { value: false, disabled: false },
        { nonNullable: true }
      ),
      filtreDateDebut: new FormControl<string>(
        { value: '', disabled: false },
        { nonNullable: true }
      ),
      filtreDateFin: new FormControl<string>(
        { value: '', disabled: false },
        { nonNullable: true }
      ),
    });

    this.filterForm.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        if (this.filterForm.valid) {
          this.evenementService.filterform.set(this.filterForm.getRawValue());
        }
      });

    effect(() => {
      this.filterForm.controls.codeClients.patchValue(
        this.clientSelection() ? [this.clientSelection()!.codeClient] : [],
        { emitEvent: true }
      );
    });
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  evenementARedditionner(evtStatut: StatutEvenement): boolean {
    return evtStatut === StatutEvenement.ARedditionner;
  }

  acomptePossible(evtStatut: StatutEvenement): boolean {
    return evtStatut !== StatutEvenement.Reverse;
  }

  statut: string = 'EnAttenteDeFacturation';
  private idRedditionFacture = new BehaviorSubject<string>('');

  ngOnInit() {
    this.sort?.sortChange.subscribe((sort) => {
      this.sortData(sort);
    });
  }

  ngAfterViewInit() {
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      const paramClient = params.get('codeClient');
      const paramARedditionner = params.get('aRedditionner');

      this.evenementService.urlClient.set(paramClient ?? '');

      if (paramARedditionner === 'true') {
        this.toggleChange();
      }
    });
  }

  sortData(sort: Sort) {
    this.filterForm.controls.orderingField.setValue(sort.active);
    let direction = 0;
    switch (sort.direction) {
      case 'asc':
        direction = 0;
        break;
      case 'desc':
        direction = 1;
        break;
      default:
        direction = 0;
        break;
    }

    this.filterForm.controls.orderingDirection.setValue(direction);
  }

  clearClient() {
    this.clientSelection.set(undefined);
  }

  displayClient(client?: Client): string {
    return client && client.libelleClient ? client.libelleClient : '';
  }

  clearEvenement() {
    this.filterForm.controls.rechercheEvenement.setValue('');
  }

  to: string | number | NodeJS.Timeout | undefined;

  onMouseEnter(t: Evenement) {
    this.to = setTimeout(() => {
      this.evenementEnter = this.evenementEnter === t ? null : t;
    }, 1000);
  }
  onMouseLeave() {
    clearTimeout(this.to);
    this.evenementEnter = null;
  }

  toggleChange() {
    this.isChecked = !this.isChecked;
    this.clearClient();
    this.filterForm.reset();
    this.filterForm.controls.aRedditionnerUniquement.setValue(this.isChecked);
  }

  testIsOverflow(el: HTMLElement): boolean {
    let curOverflow = el.style.overflow;
    if (!curOverflow || curOverflow === 'visible') {
      el.style.overflow = 'hidden';
    }
    let isOverflow =
      el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
    el.style.overflow = curOverflow;
    return isOverflow;
  }

  demanderAcompte(evt: Evenement) {
    const dialogData: DialogDataInput = {
      evenement: evt,
      type: TypeDialog.acompte,
    };
    this.openDialogAcompteReddition(dialogData);
  }

  demanderReddition(evt: Evenement) {
    const dialogData: DialogDataInput = {
      evenement: evt,
      type: TypeDialog.reddition,
    };
    this.openDialogAcompteReddition(dialogData);
  }

  openDialogAcompteReddition(dialogData: DialogDataInput) {
    this.dialog
      .open(DialogAcompteRedditionValidationComponent, {
        data: dialogData,
      })
      .afterClosed()
      .subscribe((result: DialogReturn) => {
        if (result.valide) {
          if (dialogData.type === TypeDialog.acompte) {
            this.evenementService.acompteACreer.set({
              idEvenement: dialogData.evenement.id,
              idCompteBancaire: result.idCompteBancaire,
              montantAcompte: result.montantAcompte,
            });
          } else if (dialogData.type === TypeDialog.reddition) {
            this.evenementService.redditionACreer.set({
              idEvenement: dialogData.evenement.id,
              idCompteBancaire: result.idCompteBancaire,
            });
          }
        }
      });
  }

  afficherDetailEvenement(evenement: Evenement) {
    this.panelEvenement.set(evenement);
    this.voirDetailEvenement.set(true);
  }

  telechargerFactureReddition(
    idReddition: string,
    action: PdfActon = PdfActon.telecharger
  ) {
    this.factureService.redditionAction.set({
      id: idReddition,
      action: action,
    });
  }

  telechargerJustificationAcompte(
    idAcompte: string,
    action: PdfActon = PdfActon.telecharger
  ) {
    this.factureService.acompteAction.set({
      id: idAcompte,
      action: action,
    });
  }
  telechargerDocumentBlob() {
    const blob = this.pdfBlob()!;
    this.factureService.telechargerDocBlob(blob, this.filename());
  }

  fermerPanelDetails() {
    this.voirPDF.set(false);
    this.voirDetailEvenement.set(false);
    this.panelEvenement.set(undefined);
  }

  clearDate() {
    this.filterForm.controls.filtreDateDebut.reset();
    this.filterForm.controls.filtreDateFin.reset();
  }
}
