import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';
import { FeatureFlag } from './feature-flag.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  constructor(private httpClient: HttpClient) {}

  private getFeatureFlags$: Observable<FeatureFlag[]> = this.httpClient
    .get<FeatureFlag[]>(`${environment.backendUrl}feature-flags`)
    .pipe(shareReplay(1));

  isEnabled(name: string): Observable<boolean> {
    var featureFlag = this.getFeatureFlags$.pipe(
      map((featureFlags) => {
        return featureFlags.find(
          (ff) => ff.featureName.toLowerCase() === name.toLowerCase()
        );
      })
    );
    return featureFlag.pipe(map((ff) => ff?.isEnabled ?? true));
  }

  getFeaturesFlags(): Observable<{ [key: string]: boolean }> {
    return this.getFeatureFlags$.pipe(
      map((featureFlags: FeatureFlag[]) => {
        return featureFlags.reduce((acc, flag) => {
          acc[flag.featureName] = flag.isEnabled;
          return acc;
        }, {} as { [key: string]: boolean });
      })
    );
  }
}
