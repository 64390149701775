<div class="body-container">
  <div class="content-header">
    @if(this.factureEnCours()){
    <div class="facture_loader">
      <span data-text="E" data-text-before=" " class="invisible">I</span>
      <span data-text="N" data-text-before="D" class="invisible">I</span>
      <span data-text=" " data-text-before="O" class="invisible">I</span>
      <span data-text="C" data-text-before="C" class="invisible">I</span>
      <span data-text="R" data-text-before="U" class="invisible">I</span>
      <span data-text="E" data-text-before="M" class="invisible">I</span>
      <span data-text="A" data-text-before="E" class="invisible">I</span>
      <span data-text="T" data-text-before="N" class="invisible">I</span>
      <span data-text="I" data-text-before="T" class="invisible">I</span>
      <span data-text="O" data-text-before=" " class="invisible">I</span>
      <span data-text="N" data-text-before=" " class="invisible">I</span>
    </div>
    }
  </div>
  <div class="reddition_body bs_table flex aic">
    <div class="body-sub-container filter_container">
      <div class="filter">
        <mat-form-field appearance="outline" class="filter_element">
          <mat-label>Clients</mat-label>

          <input
            #clientInput
            type="text"
            matInput
            [ngModel]="clientSelection()"
            (ngModelChange)="clientSelection.set($event)"
            [matAutocomplete]="autoClient"
            (click)="$event.stopPropagation(); clearClient()"
          />
          @if(clientSelection()){
          <button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="$event.stopPropagation(); clearClient()"
          >
            <mat-icon>close</mat-icon>
          </button>
          }@else{
          <button matSuffix mat-icon-button>
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          }

          <mat-autocomplete
            #autoClient="matAutocomplete"
            [displayWith]="displayClient"
          >
            @for (client of filteredClient(); track client) {
            <mat-option [value]="client">{{ client.libelleClient }}</mat-option>
            }
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline" class="filter_element">
          <mat-label>Evènement</mat-label>
          <input
            #evtInput
            form
            [formControl]="filterForm.controls.rechercheEvenement"
            type="text"
            matInput
            value="{{ filterForm.controls.rechercheEvenement.value }}"
          />
          @if(filterForm.controls.rechercheEvenement.value ){
          <button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="$event.stopPropagation(); clearEvenement()"
          >
            <mat-icon>close</mat-icon>
          </button>
          } @if(filterForm.controls.rechercheEvenement.hasError('minlength')){
          <mat-error>Minimum 3 caractères pour lancer la recherche </mat-error>
          }
        </mat-form-field>
        <mat-form-field>
          <mat-label>Saisir les dates</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              [formControl]="filterForm.controls.filtreDateDebut"
              placeholder="Date début"
            />
            <input
              matEndDate
              [formControl]="filterForm.controls.filtreDateFin"
              placeholder="Date fin"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matIconSuffix
            (click)="clearDate()"
            (keyPress)="clearDate()"
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <mat-slide-toggle
          class="slider"
          checked="{{ isChecked }}"
          (toggleChange)="toggleChange()"
          labelPosition="before"
          >A redditionner uniquement</mat-slide-toggle
        >
      </div>
      <div class="nbr_evenement">
        <p>{{ evenements().totalCount }} évènement(s)</p>
      </div>
    </div>

    <div class="evenement_grid_container body-sub-container">
      <table
        mat-table
        [dataSource]="evenements().items"
        matSort
        (matSortChange)="sortData($event)"
        multiTemplateDataRows
        matSortActive="date"
        matSortDirection="asc"
      >
        <caption></caption>

        <ng-container matColumnDef="nom">
          <th
            class="{{ sort?.active === 'nom' ? '' : 'th_mat_cell_sort' }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            <div>Evènement</div>
          </th>
          <td
            mat-cell
            #tdElement
            *matCellDef="let row"
            class="column_content aligne_G"
            matTooltip="{{ row.nom }}"
            [matTooltipDisabled]="!testIsOverflow(tdElement)"
          >
            {{ row.nom }}
          </td>
        </ng-container>
        <ng-container matColumnDef="idAparte">
          <th
            class="{{ sort?.active === 'idAparte' ? '' : 'th_mat_cell_sort' }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            <div>Id session</div>
          </th>
          <td mat-cell *matCellDef="let row">{{ row.idAparte }}</td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th
            class="{{ sort?.active === 'date' ? '' : 'th_mat_cell_sort' }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            <div>Date & heure</div>
          </th>
          <td
            mat-cell
            #tdElement
            *matCellDef="let row"
            class="column_content"
            matTooltip="{{ row.date | date : 'dd/MM/yyyy HH:mm' }}"
            [matTooltipDisabled]="!testIsOverflow(tdElement)"
          >
            {{ row.date | date : "dd/MM/yyyy HH:mm" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="nomClient">
          <th
            class="{{ sort?.active === 'nomClient' ? '' : 'th_mat_cell_sort' }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            <div>Client</div>
          </th>
          <td
            mat-cell
            #tdElement
            *matCellDef="let row"
            class="column_content"
            matTooltip="{{ row.nomClient }}"
            [matTooltipDisabled]="!testIsOverflow(tdElement)"
          >
            {{ row.nomClient }}
          </td>
        </ng-container>
        <ng-container matColumnDef="statutRapprochementOk">
          <th mat-header-cell *matHeaderCellDef>
            <div>Rapprochement</div>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="capsule">
              <div
                class="caps {{
                  row.statutRapprochementOk
                    | rapprochementStatutClass
                      : row.datePlusVieuxReglementNonRapproche
                }}"
              >
                @if (row.statutRapprochementOk){
                <mat-icon>done</mat-icon>
                }@else{
                <mat-icon>close</mat-icon>
                }

                <p>{{ row.statutRapprochementOk ? "ok" : "ko" }}</p>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="montantTotal">
          <th mat-header-cell *matHeaderCellDef>
            <div>Montant total</div>
          </th>
          <td mat-cell *matCellDef="let row" class="aligne_D">
            {{ row.montantTotal.toFixed(2) }}€
          </td>
        </ng-container>
        <ng-container matColumnDef="totalReverse">
          <th mat-header-cell *matHeaderCellDef>
            <div>Total reversé</div>
          </th>
          <td mat-cell *matCellDef="let row" class="progress_bar_container">
            <div
              class="progress_bar {{
                (row.totalReverse / (row.totalReverse + row.montantDu)) *
                  100 ===
                100
                  ? 'barre_ok'
                  : ''
              }}"
            >
              <div class="progress_bar_montant">
                {{ row.totalReverse.toFixed(2) }}€
                <mat-progress-bar
                  mode="determinate"
                  value="
                    {{
                    (row.totalReverse /
                      (row.totalReverse + row.montantDu == 0
                        ? 1
                        : row.totalReverse + row.montantDu)) *
                      100
                  }}                  
                  "
                ></mat-progress-bar>
              </div>
              <div class="progress_bar_content">
                @if((row.totalReverse + row.montantDu) ===0){
                {{ 0 | percent }}
                }@else{
                {{
                  row.totalReverse / (row.totalReverse + row.montantDu)
                    | percent
                }}}
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="montantDu">
          <th mat-header-cell *matHeaderCellDef>
            <div>Reste dû</div>
          </th>
          <td mat-cell *matCellDef="let row" class="aligne_D">
            {{ row.montantDu.toFixed(2) }}€
          </td>
        </ng-container>
        <ng-container matColumnDef="statutEvenement">
          <th mat-header-cell *matHeaderCellDef>
            <div>Statut</div>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="capsule">
              <div
                class="caps {{ row.statutEvenement | evenementStatutClass }}"
              >
                <div>{{ row.statutEvenement | evenementStatut }}</div>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="slider">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="headertest"
            aria-label="row actions"
          >
            &nbsp;
          </th>
          <td
            mat-cell
            *matCellDef="let evt"
            (click)="$event.stopPropagation()"
            class="mouseEnter"
            [@mouseEnter]="evt == evenementEnter ? 'oui' : 'non'"
          >
            <div class="listbtn">
              @if(!evt.statutRapprochementOk){
              <div class="btn">
                <a
                  [routerLink]="['/rapprochement/rapprochement-manuel']"
                  [queryParams]="{ codeClient: evt.nomClient }"
                >
                  <button type="button" mat-flat-button class="btn-secondaire">
                    Vérifier les rapprochements
                  </button></a
                >
              </div>
              } @if( evt.montantDu> 0 && AcomptePossible(evt.statutEvenement)){
              <div class="btn">
                <button
                  type="button"
                  (click)="demanderAcompte(evt)"
                  mat-flat-button
                  class="btn-secondaire"
                >
                  Faire une demande d'acompte
                </button>
              </div>
              } @if(evt.statutRapprochementOk &&
              EvenementARedditionner(evt.statutEvenement)){
              <div class="btn">
                <button
                  type="button"
                  (click)="demanderReddition(evt)"
                  mat-flat-button
                  class="btn-primaire"
                >
                  Redditionner l'évènement
                </button>
              </div>
              }

              <button
                class="evt_icon_btn"
                mat-mini-fab
                aria-label="Voir les détails"
                (click)="afficherDetailEvenement(evt)"
              >
                <mat-icon>manage_search</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="nomColumnsAvecDetail"></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: nomColumnsAvecDetail"
          class="element-row"
          [class.expanded-row]="evenementDetail === element"
          (mouseenter)="onMouseEnter(element)"
          (mouseleave)="onMouseLeave()"
          (click)="
            evenementDetail = evenementDetail === element ? null : element
          "
        ></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">Aucune donnée trouvée</td>
        </tr>
      </table>

      <mat-paginator
        (page)="handlePageEvent($event)"
        [pageSizeOptions]="[25, 50, 75, 100]"
        [pageIndex]="filterForm.controls.numeroPage.value"
        [pageSize]="filterForm.controls.nbrParPage.value"
        [length]="evenements().totalCount"
        aria-label="Select page of users"
      ></mat-paginator>
    </div>
  </div>
  <div
    class="panel_background"
    [@showDetail]="voirDetailEvenement()"
    (click)="fermerPanelDetails()"
    (keypress)="fermerPanelDetails()"
  ></div>
  <div
    class="panel_evenement_container"
    [@slider]="voirDetailEvenement() ? 'open' : 'close'"
  >
    @defer (when panelEvenement()) { @if(panelEvenement()){
    <app-panel-evenement-detail></app-panel-evenement-detail>
    } @if(voirPDF()){
    <div class="visionneuse_document">
      <div class="visionneuse_header">
        <div class="evt_info">
          <p>{{ panelEvenement()?.idAparte }} {{ panelEvenement()?.nom }}</p>
          du {{ panelEvenement()?.date | date }}
        </div>
        <button
          class="btn-secondaire"
          mat-flat-button
          (click)="voirPDF.set(false)"
        >
          Fermer
        </button>
        <button
          class="btn-primaire"
          mat-flat-button
          (click)="telechargerDocumentBlob()"
        >
          Télécharger le document
        </button>
      </div>
      <div class="visionneuse_body">
        <app-pdf-viewer
          [blob]="pdfBlob()!"
          [filename]="filename()"
        ></app-pdf-viewer>
      </div>
    </div>
    } }
  </div>
</div>
