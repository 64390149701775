<h2 mat-dialog-title class="flex aic jcc">
  <img
    class="dialog-img"
    src="../../../assets/img/type=danger.png"
    alt="Attention"
  />
</h2>
<mat-dialog-content class="dialog-content">
  {{ data.message }}
</mat-dialog-content>
<mat-dialog-actions class="flex">
  <button type="button" mat-flat-button class="btn-secondaire flex-1" [mat-dialog-close]="refuse()">
    {{ data.btnAnnuler }}
  </button>
  <button type="button" mat-flat-button class="btn-primaire flex-1" [mat-dialog-close]="valide()">
    {{ data.btnValide }}
  </button>
</mat-dialog-actions>
