@if(loginDisplay){
<mat-sidenav-container class="sidenav-container flex">
  <mat-sidenav
    #sidenav
    mode="side"
    opened
    class="sidenav flex-1"
    [fixedInViewport]="true"
    sc
  >
    <div class="jcc aic sidenav-logo">
      <a routerLink="/home" class="logo"> </a>
    </div>

    @for( item of items; track item; ){ @if (item.avecAccordion){
    <cdk-accordion class="nav-menu-accordion">
      <cdk-accordion-item
        #accordionItem="cdkAccordionItem"
        class="nav-accordion-item aic jcc"
        role="button"
        tabindex="0"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: false }"
        [attr.id]="'accordion-header-' + item.id"
        [attr.aria-expanded]="accordionItem.expanded"
        [attr.aria-controls]="'accordion-body-' + item.id"
      >
        <div
          class="accordion-item-header"
          [ngClass]="
            accordionItem.expanded
              ? 'accordion-item-header-expanded'
              : 'not-expanded'
          "
          (click)="accordionItem.toggle()"
        >
          <div class="aic jcc item-header-content">
            <div class="item-header-content-fixe">
              <mat-icon
                aria-hidden="false"
                fontIcon="{{ item.fontIcon }}"
                class="icon"
                aria-label="rebase_edit"
              ></mat-icon>
              {{ item.Libelle }}
            </div>
            <div class="icon-chevron">
              <mat-icon
                aria-hidden="false"
                fontIcon="chevron_right"
                [style.transform]="
                  accordionItem.expanded ? 'rotate(90deg)' : ''
                "
                class="icon"
                aria-label="chevron"
              ></mat-icon>
            </div>
          </div>
        </div>
        <div
          class="accordion-item-body"
          role="region"
          [style.display]="accordionItem.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-' + item.id"
          [attr.aria-labelledby]="'accordion-header-' + item.id"
        >
          @for(sousMenu of item.sousMenu; track sousMenu; ){
          @if(sousMenu.enabled){
          <div class="nav-menu">
            <div class="nav-item">
              <a
                routerLink="{{ sousMenu.routerLink }}"
                routerLinkActive="active"
                ><mat-icon
                  aria-hidden="false"
                  fontIcon="{{ sousMenu.fontIcon }}"
                  class="icon"
                ></mat-icon>
                {{ sousMenu.Libelle }}</a
              >
            </div>
          </div>
          }}
        </div>
      </cdk-accordion-item>
    </cdk-accordion>

    }@else {
    <div class="nav-menu">
      <div class="nav-item">
        <a routerLink="{{ item.routerLink }}" routerLinkActive="active"
          ><mat-icon
            aria-hidden="false"
            fontIcon="{{ item.fontIcon }}"
            class="icon"
          ></mat-icon>
          {{ item.Libelle }}</a
        >
      </div>
    </div>
    } }

    <div class="nav_version">
      <div>{{ user() }}</div>
      <div class="deconnexion" (click)="logout()">Se déconnecter</div>
      <br />
      <div class="img_logo_footer">
        <img alt="T&L" ngSrc="../img/tick&live_logo_white.svg" fill priority />
      </div>

      <p>
        v{{ version }}
        <mat-icon
          routerLink="version"
          class="note_version"
          matTooltip="Note de version"
          featureEnabled="Version"
          >info</mat-icon
        >
      </p>
    </div>
  </mat-sidenav>

  <mat-sidenav-content class="flex-1">
    <router-outlet />
  </mat-sidenav-content>
</mat-sidenav-container>
}@else {
<app-login></app-login> } @if(isLoading()){ <app-chargement></app-chargement>}
<app-alert></app-alert>
