import { NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';

@Component({
    selector: 'app-chargement',
    imports: [NgOptimizedImage],
    templateUrl: './chargement.component.html',
    styleUrl: './chargement.component.scss'
})
export class ChargementComponent {}
