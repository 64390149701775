import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, inject } from '@angular/core';

import { Alert } from './alert.interface';
import { AlertService } from './alert.service';

@Component({
    selector: 'app-alert',
    imports: [],
    templateUrl: './alert.component.html',
    styleUrl: './alert.component.scss',
    animations: [
        trigger('fade', [
            state('false', style({ opacity: 1 })),
            state('true', style({ opacity: 0 })),
            transition('false => true', [animate(500)]),
        ]),
    ]
})
export class AlertComponent {
  private alertService = inject(AlertService);
  alerts = this.alertService.alerts;
  removeAlert(alert: Alert) {
    this.alertService.removeAlert(alert);
  }

  cssClass(alert: Alert) {
    return this.alertService.getCssClass(alert.type);
  }
}
