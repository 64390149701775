import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { ConnexionService } from './connexion.service';
import * as i0 from '@angular/core';

@Component({
    selector: 'app-login',
    imports: [],
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss'
})
export class LoginComponent {
  constructor(private conServ: ConnexionService) {}
  
  annee = new Date().getFullYear();
   login() {

     this.conServ.loginRedirect();
  }
}
