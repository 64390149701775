import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { StatutEvenement } from '../evenements.interface';

@Pipe({
  name: 'evenementStatutClass',
  standalone: true,
})
export class EvenementStatutClassPipe implements PipeTransform {
  transform(statutEvenement: StatutEvenement): string {
    switch (statutEvenement) {
      case StatutEvenement.AControler:
        return 'capsKo';
      case StatutEvenement.ARedditionner:
        return 'capsInfo';
      case StatutEvenement.Acompte:
        return 'capsInfo';
      case StatutEvenement.EnCours:
        return 'capsWait';
      case StatutEvenement.Reverse:
        return 'capsOk';
      default:
        return 'capsKo';
    }
  }
}
