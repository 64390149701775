import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ibanAutoformat',
  standalone: true,
})
export class IbanAutoformatPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      value = value
        .toUpperCase()
        .replace(/[^\dA-Z]/g, '')
        .replace(/(.{4})/g, '$1 ')
        .trim();
    }
    return value.toUpperCase();
  }
}
