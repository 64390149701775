<main class="main">
  <div class="content">
    <div class="logo">
      <img
        alt=""
        ngSrc="../../assets/img/T&LB_logo_colored.svg"
        fill
        priority
      />
    </div>
  </div>
</main>
