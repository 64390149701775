import { DatePipe, DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';

@Component({
    selector: 'app-version',
    providers: [
        {
            provide: DATE_PIPE_DEFAULT_OPTIONS,
            useValue: { dateFormat: 'dd/MM/YYYY', timezone: '+0000' },
        },
    ],
    imports: [MatExpansionModule, DatePipe, MatIconModule, MatListModule],
    templateUrl: './version.component.html',
    styleUrl: './version.component.scss'
})
export class VersionComponent {
  x: test[] = [
    {
      version: '10.20.0',
      date: new Date('01/10/2024 12:00:00'),
      content: ['truc', 'truc2', 'truc3'],
    },
    {
      version: 'v1.1',
      date: new Date('12/08/2024 12:00:00'),
      content: ['truc', 'truc2', 'truc3'],
    },
    {
      version: 'v1.0',
      date: new Date('05/07/2024 12:00:00'),
      content: ['truc', 'truc2', 'truc3'],
    },
  ];
}

export interface test {
  version: string;
  date: Date;
  content: string[];
}
